import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ImgMale from '../../images/yellow-student-boy.svg';
import ImgFemale from '../../images/yellow-student.svg'; 

const ProfileViewEdit = () => {

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const storedUser = JSON.parse(sessionStorage.getItem('user'));
  console.log(storedUser);
  const user_id = storedUser ? storedUser.id : null; // Example UUID for API call

  // Fetch user profile data
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`https://www.astsoftwaresolutions.in/api/users/getUsers/${user_id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log(response.data.data[0]);
        setUserData(response.data.data[0]);  // Assuming the data is in response.data
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setLoading(false);
      }
    };

    if (user_id) {
      fetchUserProfile();
    }
  }, [user_id]);

  // State for edit mode
  const [isEditing, setIsEditing] = useState(false);

  // Handle input changes
  const handleInputChange = (field, value) => {
    setUserData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Toggle edit mode
  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  // Save changes (Here you could also implement an actual save to the server)
  const saveChanges = () => {
    console.log('Saved data:', userData);
    setIsEditing(false);
  };

  // Cancel changes
  const cancelChanges = () => {
    setUserData(null);  // Clear data or reset to original state if needed
    setIsEditing(false);
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="parentInformation-detail tab-white-block">
      <div className="customRow d-flex justify-content-between align-items-center">
        <h2>Profile View/Edit</h2>
        <button className="btn-cancel btn btn-primary" onClick={toggleEditMode}>
          {isEditing ? 'Cancel' : 'Edit'}
        </button>
      </div>

      <div className="profile-pic text-center my-3">    
        {userData.profile_pic_url ? (
          <img
            src={userData.profile_pic_url}
            alt="Profile"
            className="rounded-circle"
            style={{ width: '150px', height: '150px' }}
          />
        ) : (
          <img
            src={userData.gender === 'male' ? ImgMale : ImgFemale}
            alt="Profile"
            className="rounded-circle"
            style={{ width: '150px', height: '150px' }}
          />
        )}
        {isEditing && (
          <div className="mt-2">
            <input
              type="text"
              className="form-control"
              value={userData.profile_pic_url}
              onChange={(e) => handleInputChange('profile_pic_url', e.target.value)}
              placeholder="Enter Profile Pic URL"
            />
          </div>
        )}
      </div>

      <div className="customRow-detail">
        <div className="row g-3">
          {Object.keys(userData).map((field) => {
            // Skip non-editable fields (You can customize the logic here)
            if (['created_at', 'user_id', 'is_email_verified', 'is_phone_verified'].includes(field)) {
              return (
                <div key={field} className="mb-3">
                  <strong>{field.replace(/_/g, ' ')}:</strong>
                  <div className="font-20 font-500 lineHeight-22">{String(userData[field])}</div>
                </div>
              );
            }

            // Render editable or non-editable field
            return (
              <div key={field} className="mb-3">
                <div className="col-md-4">
                  <div className="d-flex flex-column">
                    <div className="font-16 font-400 lineHeight-22 mb-1">
                      <strong>{field.replace(/_/g, ' ')}:</strong>
                    </div>
                  </div>
                </div>

                {isEditing ? (
                  <input
                    type="text"
                    className="form-control"
                    value={userData[field] || ''}
                    onChange={(e) => handleInputChange(field, e.target.value)}
                  />
                ) : (
                  <div className="font-20 font-500 lineHeight-22">{String(userData[field])}</div>
                )}
              </div>
            );
          })}
        </div>

        {isEditing && (
          <div className="mt-4">
            <button className="btn btn-success me-3" onClick={saveChanges}>
              Save Changes
            </button>
            <button className="btn btn-secondary" onClick={cancelChanges}>
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileViewEdit;
