import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ImgMale from '../../images/yellow-student-boy.svg';
import ImgFemale from '../../images/yellow-student.svg'; 
import ProfileViewEdit from './ProfileViewEdit';
import ParentInformation from './ParentInformation';

function ProfilePage() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const storedUser = JSON.parse(sessionStorage.getItem('user'));
  console.log(storedUser)
  const user_id = storedUser ? storedUser.id : null; // Example UUID for API call

  // Fetch user profile data
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`https://www.astsoftwaresolutions.in/api/users/getUsers/${user_id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log(response.data.data[0])
        setUserData(response.data.data[0]);  // Assuming the data is in response.data
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  // Return loading state if data is not loaded
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <section className="bg-white container-fluid alumni_profile_brife_wrap" id="alumni_profile_brife_wrap">
        <div className="max-1140 mx-auto alumni_profile_brife">
          <div className="row">
            <div className="col-12">
              <div className="alumni_profile_name_college_other_detail_wrap student_profile">
                <div className="alumni_profile_image_name_other_detail">
                  <div className="alumni_profile_image">
                    <img src={userData?.gender === 'Male' ? ImgMale : ImgFemale} alt="Profile" />
                  </div>
                  <div className="alumni_profile_name_other_detail">
                    <h2 className="mb-0">{userData?.firstname} {userData?.lastname}</h2>
                    <p className="font-14 mb-3">{userData?.date_of_birth}</p>
                    <div className="student_profile_details">
                      <div className="alumni_profile_class_college">                            
                        <div className="alumni_profile_college_phone mb-1">
                          <span className="font-16 font-500 text-black line-height-18">{userData?.phone_number}</span>
                          <a className="verifyLink" href="javascript:;"><span>Verify Now</span></a>
                        </div>
                        <div className="alumni_profile_college_phone">
                          <span className="font-16 font-500 text-black line-height-18">{userData?.email}</span>
                          <a className="verifiedLink" href="javascript:;"><i className="icon-varified font-16 me-2"></i><span>Verified</span></a>
                        </div>
                      </div> 
                      <ul className="school_other_details">
                        {/* <li>
                          <span>Board</span>
                          <b>{userData.board}</b>
                        </li> */}
                        {/* <li>
                          <span>Citizenship</span>
                          <b>{userData.citizenship}</b>
                        </li> */}
                        <li>
                          <span>Current Location</span>
                          <b>{userData?.location}</b>
                        </li>
                      </ul>
                    </div>                                                               
                  </div>                           
                </div>
                <a className="pencil-edit" href="javascript:;" data-bs-toggle="modal" data-bs-target="#basic_info_popup"></a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="alumni_profile_detail_wrap container-fluid bg-gray-f3" id="alumni_profile_detail_wrap">
        <div className="max-1140 mx-auto alumni_profile_detail">
          <div className="alumni_profile_brife_tab mb-4">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="pills-preferences-tab" data-bs-toggle="pill" data-bs-target="#pills-preferences" type="button" role="tab" aria-controls="pills-preferences" aria-selected="true">Profile</button>
              </li>
              {/* <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-parentInformation-tab" data-bs-toggle="pill" data-bs-target="#pills-parentInformation" type="button" role="tab" aria-controls="pills-parentInformation" aria-selected="false">Parent Information</button>
              </li> */}
            </ul>
          </div>

          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade active show" id="pills-preferences" role="tabpanel" aria-labelledby="pills-preferences-tab">
              <ProfileViewEdit />
            </div>

            <div className="tab-pane fade" id="pills-parentInformation" role="tabpanel" aria-labelledby="pills-parentInformation-tab">
              <ParentInformation />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProfilePage;
